import { useState } from "react";
import { getCustomersApi, createCustomerApi, updateCustomerApi } from "../api/customer";
import { useAuth } from ".";

export function useCustomer() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState(null);
  const { auth } = useAuth();

  const getCustomers = async () => {
    try {
      setLoading(true);
      const response = await getCustomersApi(auth.accessToken);
      setLoading(false);
      setCustomers(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const getActiveCustomers = async () => {
    try {
      setLoading(true);
      const response = await getCustomersApi(auth.accessToken, true);
      setLoading(false);
      setCustomers(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const addCustomer = async (data) => {
    try {
      setLoading(true);
      const response = await createCustomerApi(auth.accessToken, data);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const updateCustomer = async (id, data) => {
    try {
      setLoading(true);
      const response = await updateCustomerApi(auth.accessToken, id, data);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  return {
    loading,
    error,
    customers,
    getCustomers,
    addCustomer,
    updateCustomer,
    getActiveCustomers,
  };
}