import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { verifyUserAccountApi } from '../../../api/user';
import { Loader } from 'semantic-ui-react';
import './VerifyAccount.scss';

export function VerifyAccount() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  useEffect(() => {
    if (token && email) {
      verifyUserAccountApi(token, email)
        .then(() => {
          navigate("/login?verified=true");
        })
        .catch(() => {
          setError("El enlace ha caducado o no es válido.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("Enlace inválido.");
      setLoading(false);
    }
  }, [token, email, navigate]);

  if (loading) return <Loader active inline="centered" />;
  if (error) return <p>{error}</p>;

  return <p>Cuenta activada correctamente. Redirigiendo...</p>;
}
