import routesAdmin from "./routes.admin";
import { BasicLayout } from "../layouts";
import { Error404 } from "../pages";

const routes = [
    ...routesAdmin,
    {
        path: "*",
        component: Error404,
        layout: BasicLayout,
    },
];

export default routes;