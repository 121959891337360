import { API_URL } from '../utils/constants';

// Function to get all the siglas
export async function getSiglasApi(token) {
  try {
    const url = `${API_URL}/siglas/all`;

    const params = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}