import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { VerifyAccount } from '../../pages/Admin';
import "./VerifyLayout.scss";

export function VerifyLayout() {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      navigate('/');
    }
  }, [auth, navigate]);

  return <VerifyAccount />;
}
