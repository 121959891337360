import { AdminLayout, LoginLayout, VerifyLayout } from "../layouts";
import { HomeAdmin, ApiKeysAdmin, CustomersAdmin, LoginAdmin, MessagesAdmin, SiglasAdmin, UsersAdmin, VerifyAccount } from "../pages/Admin";

const routesAdmin = [
    {
        path: "/",
        component: HomeAdmin,
        layout: AdminLayout,
        exact: true,
    },
    {
        path: "/login",
        layout: LoginLayout,
        component: LoginAdmin,
        exact: true
    },
    {
        path: "/users",
        layout: AdminLayout,
        component: UsersAdmin,
        exact: true
    },
    {
        path: "/messages",
        layout: AdminLayout,
        component: MessagesAdmin,
        exact: true
    },
    {
        path: "/siglas",
        layout: AdminLayout,
        component: SiglasAdmin,
        exact: true
    },
    {
        path: "/laboratorios",
        layout: AdminLayout,
        component: CustomersAdmin,
        exact: true
    },
    {
        path: "/apikeys",
        layout: AdminLayout,
        component: ApiKeysAdmin,
        exact: true
    },
    {
        path: "/auth/account-verify",
        layout: VerifyLayout,
        component: VerifyAccount,
        exact: true,
    }
];

export default routesAdmin;