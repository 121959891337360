import React from 'react';
import { Modal } from 'semantic-ui-react';
import './ModalBasic.scss'

export function ModalBasic(props) {
  const { show, title, children, onClose } = props;

  return (
    <Modal className='modal-basic' open={show} onClose={onClose} size='tiny'>
      {title && <Modal.Header>{title}</Modal.Header>}
      <Modal.Content>
        <Modal.Description>
          {children}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}