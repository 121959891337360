import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { map } from 'lodash';
import './TableSiglas.scss';

export function TableSiglas(props) {
  const { siglas } = props;

  return (
    <Table className='table-siglas-admin'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nombre</Table.HeaderCell>
          <Table.HeaderCell>Área</Table.HeaderCell>
          <Table.HeaderCell>Actualización</Table.HeaderCell>
          <Table.HeaderCell>Registro</Table.HeaderCell>
          <Table.HeaderCell>Estado</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Array.isArray(siglas) ? (
          map(siglas, (sigla, index) => (
            <Table.Row key={index}>
              <Table.Cell>{sigla.name}</Table.Cell>
              <Table.Cell>{sigla.area}</Table.Cell>
              <Table.Cell>{sigla.updated_at}</Table.Cell>
              <Table.Cell>{sigla.created_at}</Table.Cell>
              <Table.Cell>{sigla.status ? <Icon color='green' name='check' /> : <Icon color='red' name='close' />}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan='5' textAlign='center'>
              {siglas.detail || 'Error desconocido'}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}
