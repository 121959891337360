import { API_URL } from "../utils/constants";

// Function to get all the keys
export async function getAPIKeysApi(token) {
  try {
    const url = `${API_URL}/keys/all`;

    const params = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to create a new API key
export async function createAPIKeyApi(token, data) {
  try {
    const url = `${API_URL}/keys`;

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to update an API key
export async function updateAPIKeyApi(token, id, data) {
  try {
    const url = `${API_URL}/keys/${id}`;

    const params = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}