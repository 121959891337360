import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useFormik } from 'formik';
import { loginApi } from '../../../api/user';
import { useAuth } from '../../../hooks';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import "./LoginForm.scss";

export function LoginForm() {
  const { login } = useAuth();
  
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      const response = await loginApi(formValue);
      if (response.error) {
        toast.error(response.error);
      } else {
        const { access_token, refresh_token } = response;
        login(access_token, refresh_token);
      }
    },
  });

  return (
    <Form className='login-form-admin' onSubmit={formik.handleSubmit}>
      <Form.Field>
        <label htmlFor='email'>Correo electrónico</label>
        <Form.Input
          id='email'
          name='email'
          type='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder='Correo electrónico'
          autoComplete='email'
          error={formik.errors.email}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='password'>Contraseña</label>
        <Form.Input
          id='password'
          name='password'
          type='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder='Contraseña'
          autoComplete='current-password'
          error={formik.errors.password}
        />
      </Form.Field>
      <Button type='submit' primary fluid>Iniciar sesión</Button>
    </Form>
  );
}

function initialValues() {
  return {
    email: '',
    password: '',
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required(true),
    password: Yup.string().required(true),
  };
}