import React from 'react';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import { useFormik } from 'formik';
import { useApiKey } from '../../../../hooks';
import { toast } from 'react-toastify';
import './StatusApiKeyForm.scss';

export function StatusApiKeyForm(props) {
  const { onClose, onRefetch, apiKey } = props;
  const { updateApiKey } = useApiKey();
  const formik = useFormik({
    initialValues: initialValues(apiKey),
    validateOnChange: false,
    onSubmit: async (formData) => {
      const response = await updateApiKey(apiKey.id, formData);
      if (response.error) {
        toast.error(response.error);
      } else {
        onRefetch();
        onClose();
        toast.success('API Key actualizado correctamente');
      }
    },
  });

  return (
    <Form className='add-apikey-form' onSubmit={formik.handleSubmit}>
      <Form.Field>
        <Checkbox toggle label='¿Activo?' checked={formik.values.status} onChange={(_, data) => formik.setFieldValue("status", data.checked)} />
      </Form.Field>

      <Button primary fluid type='submit' className='btn-submit'>
        Actualizar API Key
      </Button>
    </Form>
  );
}

function initialValues(apiKey) {
  return {
    status: apiKey?.status || false,
  };
}