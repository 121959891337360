import React from 'react';
import { Confirm } from 'semantic-ui-react';
import './ConfirmModal.scss';

export function ConfirmModal(props) {
  const { open, onConfirm, onCancel } = props;

  return (
    <Confirm
      open={open}
      header='Confirmar acción'
      content='¿Estás seguro de realizar esta acción?'
      onCancel={onCancel}
      onConfirm={onConfirm}
      size='tiny'
    />
  );
}