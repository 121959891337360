import React, { useState } from 'react';
import { Table, Button, Icon, Modal, Loader } from 'semantic-ui-react';
import { map } from 'lodash';
import './TableMessages.scss';

export function TableMessages(props) {
  const { messages, getMessage, loadingMessage, message } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = async (message) => {
    setModalOpen(true);
    await getMessage(message.id);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  return (
    <>
      <Table className='table-messages-admin'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Orden</Table.HeaderCell>
            <Table.HeaderCell>Paciente</Table.HeaderCell>
            <Table.HeaderCell>Serial Disp.</Table.HeaderCell>
            <Table.HeaderCell>Modelo Disp.</Table.HeaderCell>
            <Table.HeaderCell>Tipo de muestra</Table.HeaderCell>
            <Table.HeaderCell>Fecha resultado</Table.HeaderCell>
            <Table.HeaderCell>Estado</Table.HeaderCell>
            <Table.HeaderCell>Acciones</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {Array.isArray(messages) ? (
            map(messages, (message, index) => (
              <Table.Row key={index}>
                <Table.Cell>{message.lab_order}</Table.Cell>
                <Table.Cell>{message.patient_name}</Table.Cell>
                <Table.Cell>{message.device_serial}</Table.Cell>
                <Table.Cell>{message.device_model}</Table.Cell>
                <Table.Cell>{message.sample_type}</Table.Cell>
                <Table.Cell>{message.result_date}</Table.Cell>
                <Table.Cell textAlign='center'>
                  {message.status ? <Icon color='green' name='check' /> : <Icon color='red' name='close' />}
                </Table.Cell>

                <Actions message={message} openModal={openModal} />
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan='8' textAlign='center'>
                {messages.detail || 'Error desconocido'}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <Modal open={modalOpen} size='tiny' onClose={closeModal}>
        <Modal.Header>Lista de resultados</Modal.Header>
        <Modal.Content>
          {loadingMessage ? (
            <Loader active inline="centered">Cargando detalles...</Loader>
          ) : (
            message && message.parameters.length > 0 ? (
              <Table className='table-parameters-admin'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Parámetro</Table.HeaderCell>
                    <Table.HeaderCell>Unidad</Table.HeaderCell>
                    <Table.HeaderCell>Resultado</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {message.parameters.map((param, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{param.parameter}</Table.Cell>
                      <Table.Cell>{param.unit}</Table.Cell>
                      <Table.Cell>{param.result}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <p>No hay contenido para mostrar.</p>
            )
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={closeModal}>Cerrar</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

function Actions(props) {
  const { message, openModal } = props;

  return (
    <Table.Cell textAlign='right'>
      <Button color='blue' icon onClick={() => openModal(message)}>
        <Icon name="eye" />
      </Button>
    </Table.Cell>
  );
}