import React, { useState, useEffect } from 'react';
import { Form, Button, Select } from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApiKey, useCustomer } from '../../../../hooks';
import { toast } from 'react-toastify';
import './AddApiKeyForm.scss';

export function AddApiKeyForm(props) {
  const { onClose, onRefetch, apiKey } = props;
  const { addApiKey } = useApiKey();
  const { getActiveCustomers, customers } = useCustomer();
  const [customerOptions, setCustomerOptions] = useState([]);

  useEffect(() => {
    (async () => {
      await getActiveCustomers();
    })();
  }, []);

  useEffect(() => {
    if (Array.isArray(customers)) {
      const options = customers.map((customer) => ({
        key: customer.id,
        value: customer.id,
        text: customer.name,
      }));
      setCustomerOptions(options);
    } else {
      setCustomerOptions([]);
    }
  }, [customers]);

  const formik = useFormik({
    initialValues: initialValues(apiKey),
    validationSchema: Yup.object(validationApiKeySchema()),
    validateOnChange: false,
    onSubmit: async (formData) => {
      const response = await addApiKey(formData);
      if (response.error) {
        toast.error(response.error);
      } else {
        onRefetch();
        onClose();
        toast.success('API Key creado correctamente');
      }
    },
  });

  return (
    <Form className='add-apikey-form' onSubmit={formik.handleSubmit}>
      <Form.Field>
        <label htmlFor='customer_id'>Laboratorio</label>
        <Select
          id='customer_id'
          name='customer_id'
          placeholder='Seleccionar laboratorio'
          options={customerOptions}
          search
          onChange={(e, { value }) => formik.setFieldValue('customer_id', value)}
          error={formik.errors.customer_id}
        />
      </Form.Field>

      <Button primary fluid type='submit' className='btn-submit'>
        Crear API Key
      </Button>
    </Form>
  );
}

function initialValues() {
  return {
    customer_id: '',
  };
}

function validationApiKeySchema() {
  return {
    customer_id: Yup.string().required(true),
  };
}