import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { map } from 'lodash';
import './TableCustomers.scss';

export function TableCustomers(props) {
  const { customers, updateCustomer } = props;

  return (
    <Table className='table-customers-admin'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Laboratorio</Table.HeaderCell>
          <Table.HeaderCell>Ciudad</Table.HeaderCell>
          <Table.HeaderCell>Activo</Table.HeaderCell>
          <Table.HeaderCell>Actualizado</Table.HeaderCell>
          <Table.HeaderCell>Registro</Table.HeaderCell>
          <Table.HeaderCell>Acciones</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Array.isArray(customers) ? (
          map(customers, (customer, index) => (
            <Table.Row key={index}>
              <Table.Cell>{customer.id}</Table.Cell>
              <Table.Cell>{customer.name}</Table.Cell>
              <Table.Cell>{customer.city}</Table.Cell>
              <Table.Cell>{customer.is_active ? <Icon color='green' name='check' /> : <Icon color='red' name='close' />}</Table.Cell>
              <Table.Cell>{customer.updated_at}</Table.Cell>
              <Table.Cell>{customer.created_at}</Table.Cell>

              <Actions customer={customer} updateCustomer={updateCustomer} />
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan='7' textAlign='center'>
              {customers.detail || 'Error desconocido'}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

function Actions(props) {
  const { customer, updateCustomer } = props;

  return (
    <Table.Cell textAlign='right'>
      <Button color='yellow' icon onClick={() => updateCustomer(customer)}>
        <Icon name="pencil alternate" />
      </Button>
    </Table.Cell>
  );
}