import { API_URL } from "../utils/constants";

// Function to get all the customers
export async function getCustomersApi(token, active = null) {
  try {
    let url = `${API_URL}/customers/all`;
    if (active !== null) {
      url += `?active=${active}`;
    }

    const params = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to create a new customer
export async function createCustomerApi(token, data) {
  try {
    const url = `${API_URL}/customers`;

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to update a customer
export async function updateCustomerApi(token, id, data) {
  try {
    const url = `${API_URL}/customers/${id}`;

    const params = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}