import React, { useState, useEffect } from 'react';
import { AddApiKeyForm, HeaderPage, StatusApiKeyForm, TableApiKeys } from '../../components/Admin';
import { ModalBasic } from '../../components/Common';
import { useApiKey } from '../../hooks';
import { Loader } from 'semantic-ui-react';

export function ApiKeysAdmin() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { loading, apiKeys, getApiKeys } = useApiKey();

  useEffect(() => {
    getApiKeys();
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addApiKey = () => {
    setTitleModal('Nuevo API Key');
    setContentModal(
      <AddApiKeyForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }

  const updateApiKey = (apiKey) => {
    setTitleModal('Actualizar API Key');
    setContentModal(
      <StatusApiKeyForm onClose={openCloseModal} onRefetch={onRefetch} apiKey={apiKey} />
    );
    openCloseModal();
  }

  return (
    <>
      <HeaderPage title="API Keys" btnTitle="Nuevo API Key" btnClick={addApiKey} />
      {loading ? (
        <Loader active inline="centered">
          Cargando api keys...
        </Loader>
      ) : (
        <TableApiKeys apiKeys={apiKeys} updateApiKey={updateApiKey} />
      )}

      <ModalBasic
        show={showModal}
        title={titleModal}
        onClose={openCloseModal}
        children={contentModal}
      />
    </>
  );
}
