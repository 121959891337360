import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopMenu, SideMenu } from '../../components/Admin';
import { useAuth } from '../../hooks';
import "./AdminLayout.scss";

export function AdminLayout(props) {
  const { children } = props;
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate('/login');
    }
  }, [auth, navigate]);

  if (!auth) return null;

  return (
    <div className='admin-layout'>
      <div className='admin-layout__menu'>
        <TopMenu />
      </div>
      <div className='admin-layout__main-content'>
        <SideMenu>
          {children}
        </SideMenu>
      </div>
    </div>
  );
}
