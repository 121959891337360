import { API_URL } from '../utils/constants';

// Function to get all the messages
export async function getMessagesApi(token) {
  try {
    const url = `${API_URL}/messages/all`;

    const params = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to get the message by id
export async function getMessageByIdApi(token, id) {
  try {
    const url = `${API_URL}/messages/id/${id}`;

    const params = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}