import React, { useState, useEffect } from 'react';
import { HeaderPage, TableCustomers, AddEditCustomerForm } from '../../components/Admin';
import { ModalBasic } from '../../components/Common';
import { useCustomer } from '../../hooks';
import { Loader } from 'semantic-ui-react';

export function CustomersAdmin() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { loading, customers, getCustomers } = useCustomer();

  useEffect(() => {
    getCustomers();
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addCustomer = () => {
    setTitleModal('Nuevo laboratorio');
    setContentModal(
      <AddEditCustomerForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }

  const updateCustomer = (customer) => {
    setTitleModal('Editar laboratorio');
    setContentModal(
      <AddEditCustomerForm onClose={openCloseModal} onRefetch={onRefetch} customer={customer} />
    );
    openCloseModal();
  }

  return (
    <>
      <HeaderPage title="Laboratorios" btnTitle="Nuevo laboratorio" btnClick={addCustomer} />
      {loading ? (
        <Loader active inline="centered">
          Cargando laboratorios
        </Loader>
      ) : (
        <TableCustomers customers={customers} updateCustomer={updateCustomer} />
      )}

      <ModalBasic
        show={showModal}
        title={titleModal}
        onClose={openCloseModal}
        children={contentModal}
      />
    </>
  );
}
