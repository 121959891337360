import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { map } from 'lodash';
import './TableApiKeys.scss';

export function TableApiKeys(props) {
  const { apiKeys, updateApiKey } = props;

  return (
    <Table className='table-apiKeys-admin'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>API Key</Table.HeaderCell>
          <Table.HeaderCell>Laboratorio</Table.HeaderCell>
          <Table.HeaderCell>Registro</Table.HeaderCell>
          <Table.HeaderCell>Validéz</Table.HeaderCell>
          <Table.HeaderCell>Estado</Table.HeaderCell>
          <Table.HeaderCell>Acciones</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Array.isArray(apiKeys) ? (
          map(apiKeys, (apiKey, index) => (
            <Table.Row key={index}>
              <Table.Cell>{apiKey.apikey}</Table.Cell>
              <Table.Cell>{apiKey.customer_name}</Table.Cell>
              <Table.Cell>{apiKey.created_at}</Table.Cell>
              <Table.Cell>{apiKey.expiration_date}</Table.Cell>
              <Table.Cell>
                {apiKey.status ? <Icon color='green' name='check' /> : <Icon color='red' name='close' />}
              </Table.Cell>
              <Actions apiKey={apiKey} updateApiKey={updateApiKey} />
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan='6' textAlign='center'>
              {apiKeys.detail || 'Error desconocido'}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

function Actions(props) {
  const { apiKey, updateApiKey } = props;

  return (
    <Table.Cell textAlign='right'>
      <Button color='yellow' icon onClick={() => updateApiKey(apiKey)}>
        <Icon name="pencil alternate" />
      </Button>
    </Table.Cell>
  );
}