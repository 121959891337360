import { useState } from 'react';
import { getAPIKeysApi, createAPIKeyApi, updateAPIKeyApi } from '../api/apikey';
import { useAuth } from '.';

export function useApiKey() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [apiKeys, setApiKeys] = useState(null);
  const { auth } = useAuth();

  const getApiKeys = async () => {
    try {
      setLoading(true);
      const response = await getAPIKeysApi(auth.accessToken);
      setLoading(false);
      setApiKeys(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const addApiKey = async (data) => {
    try {
      setLoading(true);
      const response = await createAPIKeyApi(auth.accessToken, data);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const updateApiKey = async (id, data) => {
    try {
      setLoading(true);
      const response = await updateAPIKeyApi(auth.accessToken, id, data);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  return {
    loading,
    error,
    apiKeys,
    getApiKeys,
    addApiKey,
    updateApiKey,
  };
}
