import React, { useEffect } from 'react';
import { HeaderPage, TableMessages } from '../../components/Admin';
import { useMessage } from '../../hooks';
import { Loader } from 'semantic-ui-react';

export function MessagesAdmin() {
  const { loading, messages, getMessages, message, getMessage, loadingMessage } = useMessage();

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <>
      <HeaderPage title="Mensajes" />
      {loading ? (
        <Loader active inline="centered">
          Cargando mensajes
        </Loader>
      ) : (
        <TableMessages 
          messages={messages} 
          getMessage={getMessage}
          loadingMessage={loadingMessage}
          message={message}
        />
      )}
    </>
  );
}
