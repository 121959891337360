import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { LoginAdmin } from '../../pages/Admin';
import "./LoginLayout.scss";

export function LoginLayout() {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth && window.location.pathname !== '/login') {
      navigate('/login');
    } else if (auth && window.location.pathname === '/login') {
      navigate('/');
    }
  }, [auth, navigate]);

  return <LoginAdmin />;
}
