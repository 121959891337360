import React from 'react';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCustomer } from '../../../../hooks';
import { toast } from 'react-toastify';
import './AddEditCustomerForm.scss';

export function AddEditCustomerForm(props) {
  const { onClose, onRefetch, customer } = props;
  const { addCustomer, updateCustomer } = useCustomer();
  const formik = useFormik({
    initialValues: initialValues(customer),
    validationSchema: Yup.object(customer ? updateCustomerSchema() : newValidationSchema()),
    validateOnChange: false,
    onSubmit: async (formData) => {
      if (customer) {
        const response = await updateCustomer(customer.id, formData);
        if (response.error) {
          toast.error(response.error);
        } else {
          onRefetch();
          onClose();
          toast.success('Laboratorio actualizado correctamente');
        }
      } else {
        const response = await addCustomer(formData);
        if (response.error) {
          toast.error(response.error);
        } else {
          onRefetch();
          onClose();
          toast.success('Laboratorio creado correctamente');
        }
      }
    },
  });

  return (
    <Form className='add-edit-customer-form' onSubmit={formik.handleSubmit}>
      <Form.Field>
        <label htmlFor='name'>Laboratorio</label>
        <Form.Input
          id='name'
          name='name'
          type='text'
          placeholder='Ingresar nombre del laboratorio'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='city'>Ciudad</label>
        <Form.Input
          id='city'
          name='city'
          type='text'
          placeholder='Ingresar nombre de la ciudad'
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.errors.city}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox toggle label='¿Activo?' checked={formik.values.is_active} onChange={(_, data) => formik.setFieldValue("is_active", data.checked)} />
      </Form.Field>

      <Button primary fluid type='submit' className='btn-submit'>
        {customer ? 'Actualizar laboratorio' : 'Crear laboratorio'}
      </Button>
    </Form>
  );
}

function initialValues(customer) {
  return {
    name: customer?.name || '',
    city: customer?.city || '',
    is_active: customer?.is_active || false,
  };
}

function newValidationSchema() {
  return {
    name: Yup.string().required(true),
    city: Yup.string().required(true),
  };
}

function updateCustomerSchema() {
  return {
    name: Yup.string().required(true),
    city: Yup.string().required(true),
  };
}