import React, { useEffect } from 'react';
import { HeaderPage, TableSiglas } from '../../components/Admin';
import { useSigla } from '../../hooks';
import { Loader } from 'semantic-ui-react';

export function SiglasAdmin() {
  const { loading, siglas, getSiglas } = useSigla();

  useEffect(() => {
    getSiglas();
  }, []);

  return (
    <>
      <HeaderPage title="Siglas" />
      {loading ? (
        <Loader active inline="centered">
          Cargando siglas
        </Loader>
      ) : (
        <TableSiglas siglas={siglas} />
      )}
    </>
  );
}
