import { useState } from 'react';
import { getMessagesApi, getMessageByIdApi } from '../api/message';
import { useAuth } from '.';

export function useMessage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState(null);
  const { auth } = useAuth();

  const [loadingMessage, setLoadingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);

  const getMessages = async () => {
    try {
      setLoading(true);
      const response = await getMessagesApi(auth.accessToken);
      setLoading(false);
      setMessages(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const getMessage = async (id) => {
    try {
      setLoadingMessage(true);
      const response = await getMessageByIdApi(auth.accessToken, id);
      setLoadingMessage(false);

      if (response.parameters && typeof response.parameters === 'string') {
        response.parameters = JSON.parse(response.parameters);
      }
      
      setMessage(response);
    } catch (error) {
      setLoadingMessage(false);
      setErrorMessage(error);
    }
  }

  return {
    loading,
    error,
    messages,
    getMessages,
    loadingMessage,
    errorMessage,
    message,
    getMessage,
  };
}
