import React, { useState, useEffect } from 'react';
import { HeaderPage, TableUsers, AddEditUserForm } from '../../components/Admin';
import { ModalBasic, ConfirmModal } from '../../components/Common';
import { useUser } from '../../hooks';
import { Loader } from 'semantic-ui-react';

export function UsersAdmin() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { loading, users, getUsers, deleteUser } = useUser();

  const [showConfirm, setShowConfirm] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    getUsers();
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addUser = () => {
    setTitleModal('Nuevo usuario');
    setContentModal(
      <AddEditUserForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }

  const updateUser = (user) => {
    setTitleModal('Editar usuario');
    setContentModal(
      <AddEditUserForm onClose={openCloseModal} onRefetch={onRefetch} user={user} />
    );
    openCloseModal();
  }

  const openConfirmDeleteUser = (user) => {
    setUserToDelete(user);
    setShowConfirm(true);
  };

  const confirmDeleteUser = async () => {
    await deleteUser(userToDelete.id);
    setShowConfirm(false);
    onRefetch();
  };

  const cancelDeleteUser = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <HeaderPage title="Usuarios" btnTitle="Nuevo usuario" btnClick={addUser} />
      {loading ? (
        <Loader active inline="centered">
          Cargando usuarios
        </Loader>
      ) : (
        <TableUsers users={users} updateUser={updateUser} onDeleteUser={openConfirmDeleteUser} />
      )}

      <ModalBasic
        show={showModal}
        title={titleModal}
        onClose={openCloseModal}
        children={contentModal}
      />

      <ConfirmModal
        open={showConfirm}
        onCancel={cancelDeleteUser}
        onConfirm={confirmDeleteUser}
      />
    </>
  );
}
