import React, { useState, useEffect, createContext } from 'react';
import { setTokens, getTokens, removeTokens } from '../api/token';
import { useUser } from '../hooks';
import { refreshTokenApi } from '../api/user';

export const AuthContext = createContext({
  auth: undefined,
  login: () => null,
  logout: () => null,
});

export function AuthProvider(props) {
  const { children } = props;
  const [auth, setAuth] = useState(undefined);
  const { getMe } = useUser();
  
  useEffect(() => {
    (async () => {
      const { accessToken, refreshToken } = getTokens();
      if (accessToken) {
        const me = await getMe(accessToken);
        setAuth({ accessToken, refreshToken, me });
      } else {
        setAuth(null);
      }
    })();
  }, []);

  // Función para hacer login y guardar tokens
  const login = async (accessToken, refreshToken) => {
    setTokens(accessToken, refreshToken);
    const me = await getMe(accessToken);
    setAuth({ accessToken, refreshToken, me });
  }

  // Función para hacer logout y limpiar tokens
  const logout = () => {
    if (auth) {
      removeTokens();
      setAuth(null);
    }
  }

  // Función para refrescar el token (opcional: aquí podría ir la lógica si el accessToken expira)
  const refreshToken = async () => {
    const { refreshToken } = getTokens();
    if (!refreshToken) return;

    // Intentamos refrescar el accessToken usando el refreshToken
    const newTokens = await refreshTokenApi(refreshToken);
    if (newTokens) {
      setTokens(newTokens.access_token, newTokens.refresh_token);
      setAuth({ accessToken: newTokens.access_token, refreshToken: newTokens.refresh_token, me: auth.me });
    } else {
      logout();
    }
  };

  const valueContext = {
    auth,
    login,
    logout,
    refreshToken,
  };

  if (auth === undefined) {
    return null;
  }

  return (
    <AuthContext.Provider value={valueContext}>
      {children}
    </AuthContext.Provider>
  );
}