import React from 'react';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUser } from '../../../../hooks';
import { toast } from 'react-toastify';
import './AddEditUserForm.scss';

export function AddEditUserForm(props) {
  const { onClose, onRefetch, user } = props;
  const { addUser, updateUser } = useUser();
  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: Yup.object(user ? updateUserSchema() : newValidationSchema()),
    validateOnChange: false,
    onSubmit: async (formData) => {
      if (user) {
        const response = await updateUser(user.id, formData);
        if (response.error) {
          toast.error(response.error);
        } else {
          onRefetch();
          onClose();
          toast.success('Usuario actualizado correctamente');
        }
      } else {
        const response = await addUser(formData);
        if (response.error) {
          toast.error(response.error);
        } else {
          onRefetch();
          onClose();
          toast.success('Usuario creado correctamente');
        }
      }
    },
  });

  return (
    <Form className='add-edit-user-form' onSubmit={formik.handleSubmit}>
      <Form.Field>
        <label htmlFor='first_name'>Nombres</label>
        <Form.Input
          id='first_name'
          name='first_name'
          type='text'
          placeholder='Ingresar nombres'
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.errors.first_name}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='last_name'>Apellidos</label>
        <Form.Input
          id='last_name'
          name='last_name'
          type='text'
          placeholder='Ingresar apellidos'
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.errors.last_name}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='email'>Correo electrónico</label>
        <Form.Input
          id='email'
          name='email'
          type='email'
          placeholder='Ingresar correo electrónico'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor='password'>Contraseña</label>
        <Form.Input
          id='password'
          name='password'
          type='password'
          placeholder='Ingresar contraseña'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox toggle label='¿Es administrador?' checked={formik.values.is_staff} onChange={(_, data) => formik.setFieldValue("is_staff", data.checked)} />
      </Form.Field>
      <Button primary fluid type='submit' className='btn-submit'>
        {user ? 'Actualizar usuario' : 'Crear usuario'}
      </Button>
    </Form>
  );
}

function initialValues(user) {
  return {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    password: '',
    is_staff: user?.is_staff || false,
  };
}

function newValidationSchema() {
  return {
    first_name: Yup.string().required(true),
    last_name: Yup.string().required(true),
    email: Yup.string().email(true).required(true),
    password: Yup.string().required(true),
  };
}

function updateUserSchema() {
  return {
    first_name: Yup.string().required(true),
    last_name: Yup.string().required(true),
    email: Yup.string().email(true).required(true),
    password: Yup.string(),
  };
}