import { useState } from 'react';
import { getSiglasApi } from '../api/sigla';
import { useAuth } from '.';

export function useSigla() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [siglas, setSiglas] = useState(null);
  const { auth } = useAuth();

  const getSiglas = async () => {
    try {
      setLoading(true);
      const response = await getSiglasApi(auth.accessToken);
      setLoading(false);
      setSiglas(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  return {
    loading,
    error,
    siglas,
    getSiglas,
  };
}
