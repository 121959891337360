import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { map } from 'lodash';
import './TableUsers.scss';

export function TableUsers(props) {
  const { users, updateUser, onDeleteUser } = props;

  return (
    <Table className='table-users-admin'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nombre completo</Table.HeaderCell>
          <Table.HeaderCell>Correo</Table.HeaderCell>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Activo</Table.HeaderCell>
          <Table.HeaderCell>Actualizado</Table.HeaderCell>
          <Table.HeaderCell>Registro</Table.HeaderCell>
          <Table.HeaderCell>Acciones</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Array.isArray(users) ? (
          map(users, (user, index) => (
            <Table.Row key={index}>
              <Table.Cell>{user.first_name} {user.last_name}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{user.is_staff ? <Icon color='green' name='check' /> : <Icon color='red' name='close' />}</Table.Cell>
              <Table.Cell>{user.is_active ? <Icon color='green' name='check' /> : <Icon color='red' name='close' />}</Table.Cell>
              <Table.Cell>{user.updated_at}</Table.Cell>
              <Table.Cell>{user.created_at}</Table.Cell>

              <Actions user={user} updateUser={updateUser} onDeleteUser={onDeleteUser} />
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan='7' textAlign='center'>
              {users.detail || 'Error desconocido'}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

function Actions(props) {
  const { user, updateUser, onDeleteUser } = props;

  return (
    <Table.Cell textAlign='right'>
      <Button color='yellow' icon onClick={() => updateUser(user)}>
        <Icon name="pencil alternate" />
      </Button>
      <Button negative icon onClick={() => onDeleteUser(user)}>
        <Icon name="trash alternate" />
      </Button>
    </Table.Cell>
  );
}