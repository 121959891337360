import { API_URL } from "../utils/constants";

// Function to login the user
export async function loginApi(formData) {
  try {
    const url = `${API_URL}/auth/login`;
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append('username', formData.email);
    urlEncodedData.append('password', formData.password);

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: urlEncodedData.toString(),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

export async function refreshTokenApi(refreshToken) {
  try {
    const url = `${API_URL}/auth/refresh`;

    const params = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${refreshToken}`,
      },
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error al refrescar el token.');
    }
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to get the user information
export async function getMeApi(token) {
  try {
    const url = `${API_URL}/users/me`;

    const params = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to get all the users
export async function getUsersApi(token) {
  try {
    const url = `${API_URL}/users/all`;

    const params = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to create a new user
export async function createUserApi(token, data) {
  try {
    const url = `${API_URL}/users`;

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to update a user
export async function updateUserApi(token, id, data) {
  try {
    const url = `${API_URL}/users/${id}`;

    const params = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to delete a user
export async function deleteUserApi(token, id) {
  try {
    const url = `${API_URL}/users/${id}`;

    const params = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la solicitud.');
    }

    return true;
  } catch (error) {
    return { error: error.message };
  }
}

// Function to verify user account
export async function verifyUserAccountApi(token, email) {
  try {
    const url = `${API_URL}/users/verify`;

    const data = {
      token,
      email,
    };

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'Error en la verificación de la cuenta.');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}