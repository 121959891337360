import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import './SideMenu.scss';

export function SideMenu(props) {
  const { children } = props;
  const { pathname } = useLocation();

  return (
    <div className='side-menu-admin'>
      <MenuLeft pathname={pathname} />
      <div className='content'>{children}</div>
    </div>
  );
}

function MenuLeft(props) {
  const { pathname } = props;
  const { auth } = useAuth();

  return (
    <Menu fixed='left' borderless className='side' vertical>
      <Menu.Item as={Link} to={"/"} active={pathname === "/"}>
        <Icon name='dashboard' /> Dashboard
      </Menu.Item>

      <Menu.Item as={Link} to={"/messages"} active={pathname === "/messages"}>
        <Icon name='rss' /> Mensajes
      </Menu.Item>

      <Menu.Item as={Link} to={"/siglas"} active={pathname === "/siglas"}>
        <Icon name='tags' /> Siglas
      </Menu.Item>

      <Menu.Item as={Link} to={"/laboratorios"} active={pathname === "/laboratorios"}>
        <Icon name='lab' /> Laboratorios
      </Menu.Item>

      <Menu.Item as={Link} to={"/apikeys"} active={pathname === "/apikeys"}>
        <Icon name='key' /> API Keys
      </Menu.Item>

      {auth.me?.is_staff && (
        <Menu.Item as={Link} to={"/users"} active={pathname === "/users"}>
          <Icon name='users' /> Usuarios
        </Menu.Item>
      )}
    </Menu>
  )
}